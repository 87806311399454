import React from "react";
import { ItemListProps } from "./Nav/ItemList";

const AccountMenu: ItemListProps[] = [
    {
        name: "Dashboard.Dashboard",
        path: "/dashboard",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                icon-name="monitor"
                data-lucide="monitor"
                className="lucide lucide-monitor"
            >
                <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
                <line x1="8" y1="21" x2="16" y2="21"></line>
                <line x1="12" y1="17" x2="12" y2="21"></line>
            </svg>
        ),
        childs: [],
    },
    {
        name: "Wallets.My_Wallets",
        path: "/accounts",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-wallet"
            >
                {" "}
                <path d="M21 12V7H5a2 2 0 0 1 0-4h14v4" />{" "}
                <path d="M3 5v14a2 2 0 0 0 2 2h16v-5" />{" "}
                <path d="M18 12a2 2 0 0 0 0 4h4v-4Z" />{" "}
            </svg>
        ),
        childs: [],
    },
    {
        name: "Wallets.My_Transactions",
        path: "/transactions",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-card-list"
                viewBox="0 0 16 16"
            >
                {" "}
                <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />{" "}
                <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />{" "}
            </svg>
        ),
        childs: [],
    },
    {
        name: "Switch_join_account.Switch_join_account",
        path: "/switch-join-account",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.65"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="users-cptjoint"
                shape-rendering="geometricPrecision"
                text-rendering="geometricPrecision"
            >
                <path
                    d="M14,19c0-3.313708-2.686292-6-6-6s-6,2.686292-6,6"
                    transform="translate(-1.277267 0)"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.65"
                />
                <circle
                    r="4"
                    transform="translate(6.722733 9)"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.65"
                />
                <circle
                    r="4"
                    transform="translate(17.362118 9)"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.65"
                />
                <path
                    d="M14,19c0-3.313708-2.881739-6.03602-6-6s-5.277267,1.890939-5.277267,3.460676"
                    transform="translate(9.277267 0.000346)"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.65"
                />
            </svg>
        ),
        childs: [],
    },
    {
        name: "Account18.Account18",
        path: "/child-accounts",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                icon-name="users-child"
                className="user-child mr-[-4px]"
            >
                <path
                    d="M14,19c0-3.313708-2.686292-6-6-6s-6,2.686292-6,6"
                    transform="translate(0 0.042425)"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <circle
                    r="3.99805"
                    transform="matrix(.92708 0 0 0.9333 7.99805 8.99805)"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14,18.241827c0-2.894472-2.856078-5.226202-6-5.226202s-6,2.33173-6,5.226202"
                    transform="matrix(.522982 0 0 0.755807 13.5316 5.40971)"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.90"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <circle
                    r="4"
                    transform="matrix(.620043 0 0 0.603823 17.715456 12.72943)"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.90"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        ),
        childs: [],
    },
    {
        name: "Switch_join_account.Switch_origin_account",
        path: "/switch-origin-account",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2.50"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-user-2 mx-auto"
            >
                <circle cx="12" cy="8" r="5" />
                <path d="M20 21a8 8 0 1 0-16 0" />
            </svg>
        ),
        childs: [],
    },
];
export default AccountMenu;
