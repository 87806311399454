import React, { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
} from "chart.js";
import User from "../../../Models/User";
import UserService from "../../../Services/User/UserService";
import UtilsService from "../../../Services/UtilsService";
import translator from "../../../Components/Extra/Translation/Translate";
import alert_message from "../../../Services/Alert/AlertMessage";
import TransactionService from "../../../Services/Wallets/TransactionService";
import ComptabilityService from "../../../Services/Comptability/Comptability";
import CardInfo from "../Dashboard/CardInfo";
import { useBaseCurrency } from "../../../App/Provider/BaseCurrencyProvider";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
);

interface DateValue {
    startDate: Date;
    endDate: Date;
}

export interface Raport {
    cb: 0;
    sum_amount_kalypay: 0;
    sum_fidelity: 0;
    sum_withdrawal: 0;
    fee_swap: 0;
    swap: 0;
    sum_commission: 0;
    count_user: {
        total: 0;
        active: 0;
    };
}
const AdminFluxFeesAccounting: React.FC = () => {
    const { base: baseCurrency } = useBaseCurrency();
    const { translate } = translator();
    const [users, setUsers] = useState<User[]>([]);

    const [feeData, setFeeData] = useState<any>();
    const [sumFee, setSumFee] = useState<number>();
    const total_benefice = useMemo<string>(() => {
        return UtilsService.formatAmount(
            Number(feeData?.swap) +
                Number(feeData?.withdrawal) +
                Number(feeData?.deposit_kalypay) +
                Number(feeData?.deposit_visa),
            2,
        );
    }, [feeData]);

    const getData = async () => {
        try {
            const response = await ComptabilityService.getData();
            const sumFee = await ComptabilityService.getSumFee();
            setFeeData(response.fees);
            setSumFee(sumFee);
        } catch (err: any) {
            alert_message.error(err);
        }
    };

    const [value, setValue] = useState<DateValue>({
        startDate: new Date(),
        endDate: new Date(),
    });

    const handleValueChange = (newValue: DateValue) => {
        setValue(newValue);
    };

    const getUsers = async () => {
        try {
            const res = await UserService.getTopFive();
            setUsers(res.users);
        } catch (err: any) {}
    };

    const [totalBonuses, setTotalBonuses] = useState<number | undefined>();
    const getGeneralReportBonusTotal = async () => {
        const res = await TransactionService.getGeneralReportBonusTotal();
        setTotalBonuses(res);
    };
    const [dashboardInfos, setDashboardInfos] = useState<any>(null);
    const getDashboardInfos = async () => {
        try {
            const response = await ComptabilityService.getData();
            setDashboardInfos(response);
        } catch (err: any) {
            alert_message.error(err);
        }
    };

    const [amountFeeData, setAmountFeeData] = useState<any>(null);
    const getSumAmountFeeData = async () => {
        try {
            const response = await ComptabilityService.getSumAmountFee();
            setAmountFeeData(response);
        } catch (err: any) {
            alert_message.error(err);
        }
    };

    useEffect(() => {
        getUsers();

        getGeneralReportBonusTotal();
        // getData();

        getDashboardInfos();
        getSumAmountFeeData();
    }, []);
    return (
        <div>
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 2xl:col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12">
                            <div className="intro-y mt-10 items-center">
                                <h2 className="mr-auto text-lg font-medium !capitalize">
                                    {translate("ACCOUNTING", "Flux")}
                                </h2>
                            </div>
                            <div className="mt-2 grid grid-cols-12 gap-6">
                                {amountFeeData && baseCurrency && (
                                    <>
                                        <CardInfo
                                            data={amountFeeData.depot}
                                            label={translate(
                                                "All_Transactions_Admin",
                                                "Deposits",
                                            )}
                                            isFee={true}
                                            baseCurrency={baseCurrency}
                                        />
                                        <CardInfo
                                            data={amountFeeData.exchange}
                                            label={translate(
                                                "All_Transactions_Admin",
                                                "Swap",
                                            )}
                                            isFee={true}
                                            baseCurrency={baseCurrency}
                                        />
                                        <CardInfo
                                            data={amountFeeData.withdrawal}
                                            label={translate(
                                                "All_Transactions_Admin",
                                                "Withdrawals",
                                            )}
                                            isFee={true}
                                            baseCurrency={baseCurrency}
                                        />
                                        <CardInfo
                                            data={amountFeeData.marchand}
                                            label={translate(
                                                "ACCOUNTING",
                                                "Payment_Merchant",
                                            )}
                                            isFee={true}
                                            baseCurrency={baseCurrency}
                                        />
                                        <CardInfo
                                            data={amountFeeData.rechargement_cb}
                                            label={translate(
                                                "ACCOUNTING",
                                                "CB_Topup",
                                            )}
                                            isFee={true}
                                            baseCurrency={baseCurrency}
                                        />
                                        <CardInfo
                                            data={amountFeeData.achats_crypto}
                                            label={translate(
                                                "ACCOUNTING",
                                                "Buy_Crypto",
                                            )}
                                            isFee={true}
                                            baseCurrency={baseCurrency}
                                        />
                                        <CardInfo
                                            data={amountFeeData?.payeer}
                                            label={translate(
                                                "ACCOUNTING",
                                                "Gateway_Payeer",
                                            )}
                                            isFee={true}
                                            baseCurrency={baseCurrency}
                                        />

                                        <CardInfo
                                            data={amountFeeData.gateway_cb}
                                            label={translate(
                                                "ACCOUNTING",
                                                "Gateway_CB",
                                            )}
                                            isFee={true}
                                            baseCurrency={baseCurrency}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminFluxFeesAccounting;
