import FidelityModel, {
    IFidelity,
    ISubscribeFidelity,
    WithdrawReduction,
} from "../../Models/Fidelity";
import OrderCard from "../../Models/OrderCard";
import { IOrderCard } from "../../Models/UserCard";
import axios from "../AxiosInstance";
import { mapOrderCard } from "../OrderCard/OrderCardService";
import UtilsService from "../UtilsService";

export enum FidelityOrderPaymentType {
    KALYPAY = "kalypay",
    VISA = "visa",
    WALLET_FIAT = "wallet_fiat",
}

export enum AllowedCountry {
    EUROPE = "europe",
    WORLD = "world",
}

export enum FidelityCardType {
    BLUE = "blue",
    GOLD = "gold",
    BLACK = "black",
    BLACK_BUSINESS = "black_business",
    BLUE_BUSINESS = "blue_business",
    GOLD_BUSINESS = "gold_business",
}

const map = (item: any): FidelityModel => {
    const fidelity = new FidelityModel(
        item.name,
        item.configuration,
        item.price,
        item.klc_value,
    );

    fidelity.created_at = item.created_at;
    fidelity.updated_at = item.updated_at;
    fidelity.photo = UtilsService.getPhotoUrl(item.photo);
    fidelity.id = item.id;
    fidelity.sub_title = item.sub_title;
    fidelity.is_text_klc_value = item.is_text_klc_value;
    fidelity.is_text_price = item.is_text_price;
    fidelity.text_klc_value = item.text_klc_value;
    fidelity.text_price = item.text_price;
    fidelity.discount_3 = item.discount_3;
    fidelity.discount_6 = item.discount_6;
    fidelity.discount_12 = item.discount_12;
    fidelity.discount_24 = item.discount_24;
    fidelity.withdrawalMonthlyLimit = item.withdrawalMonthlyLimit;
    fidelity.paymentMonthlyLimit = item.paymentMonthlyLimit;
    fidelity.withdrawFreeMonthly = item.withdrawFreeMonthly;
    fidelity.feesWithdrawMonthlyFreeLimit = item.feesWithdrawMonthlyFreeLimit;
    fidelity.type = item.type;

    fidelity.price_change_pin = item.price_change_pin;
    fidelity.price_forgot_pin = item.price_forgot_pin;
    fidelity.price_virtual_card = item.price_virtual_card;
    fidelity.free_virtual_card = item.free_virtual_card;
    fidelity.price_virtual_card_ephemere = item.price_virtual_card_ephemere;
    fidelity.free_virtual_card_ephemere = item.free_virtual_card_ephemere;
    fidelity.price_virements = item.price_virements;
    fidelity.free_virements = item.free_virements;

    fidelity.fidelity_card_type = item.fidelity_card_type;
    fidelity.allowed_country = item.allowed_country;

    return fidelity;
};

const createFormData = (payload: IFidelity): FormData => {
    const formData = new FormData();
    formData.append("name", payload.name);
    formData.append("sub_title", payload.sub_title);
    formData.append("text_klc_value", payload.text_klc_value);
    formData.append("text_price", payload.text_price);
    formData.append("configuration", payload.configuration);
    formData.append("klc_value", `` + payload.klc_value);
    formData.append("price", `` + payload.price);
    formData.append("discount_3", `` + payload.discount_3);
    formData.append("discount_6", `` + payload.discount_6);
    formData.append("discount_12", `` + payload.discount_12);
    formData.append("discount_24", `` + payload.discount_24);
    formData.append(
        "withdrawalMonthlyLimit",
        `` + payload.withdrawalMonthlyLimit,
    );
    formData.append("withdrawFreeMonthly", `` + payload.withdrawFreeMonthly);
    formData.append(
        "feesWithdrawMonthlyFreeLimit",
        `` + payload.feesWithdrawMonthlyFreeLimit,
    );
    formData.append("paymentMonthlyLimit", `` + payload.paymentMonthlyLimit);
    if (payload.is_text_price) {
        formData.append("is_text_price", "1");
    }
    if (payload.is_text_klc_value) {
        formData.append("is_text_klc_value", "1");
    }

    if (payload.photo) {
        formData.append("photo", payload.photo);
    }
    formData.append("type", `` + payload.type);

    formData.append("price_change_pin", `` + payload.price_change_pin);
    formData.append("price_forgot_pin", `` + payload.price_forgot_pin);
    formData.append("price_virtual_card", `` + payload.price_virtual_card);
    formData.append("free_virtual_card", `` + payload.free_virtual_card);
    formData.append(
        "price_virtual_card_ephemere",
        `` + payload.price_virtual_card_ephemere,
    );
    formData.append(
        "free_virtual_card_ephemere",
        `` + payload.free_virtual_card_ephemere,
    );
    formData.append("price_virements", `` + payload.price_virements);
    formData.append("free_virements", `` + payload.free_virements);
    formData.append("allowed_country", `` + payload.allowed_country);
    formData.append("fidelity_card_type", `` + payload.fidelity_card_type);

    return formData;
};

const getAll = async (): Promise<Array<FidelityModel>> => {
    return axios
        .get(`/fidelity`)
        .then(({ data }) => {
            const res: FidelityModel[] = data.map((item: any) => map(item));
            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getById = async (id: number): Promise<FidelityModel> => {
    return axios
        .get(`/fidelity/${id}`)
        .then(({ data }) => {
            const res: FidelityModel = map(data);

            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getLoyaltyData = async (): Promise<{
    current: FidelityModel | undefined;
    next: FidelityModel | undefined;
    last: FidelityModel | undefined;
}> => {
    return axios
        .get(`/fidelity/loyalty-data`)
        .then(({ data }) => {
            return {
                current: data.current ? map(data.current) : undefined,
                next: data.next ? map(data.next) : undefined,
                last: data.last ? map(data.last) : undefined,
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getDataLoyalty = async (): Promise<{
    current: FidelityModel | undefined;
    next: FidelityModel | undefined;
    last: FidelityModel | undefined;
}> => {
    return axios
        .get(`/fidelity/data-loyalty`)
        .then(({ data }) => {
            return {
                current: data.current ? map(data.current) : undefined,
                next: data.next ? map(data.next) : undefined,
                last: data.last ? map(data.last) : undefined,
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getNextLoyalty = async (): Promise<FidelityModel> => {
    return axios
        .get(`/fidelity/next-loyalty`)
        .then(({ data }) => {
            const res: FidelityModel = map(data);

            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getLastLoyalty = async (): Promise<FidelityModel> => {
    return axios
        .get(`/fidelity/last-loyalty`)
        .then(({ data }) => {
            const res: FidelityModel = map(data);

            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const deleteById = async (id?: number) => {
    return axios
        .delete(`/fidelity/${id}`)
        .then((res) => Promise.resolve(res.data.message))
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const create = async (payload: IFidelity): Promise<FidelityModel | string> => {
    const formData = createFormData(payload);

    return axios
        .post("/fidelity", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(() => {
            return Promise.resolve("Fidelité créé avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const update = async (
    notice_id: number,
    payload: IFidelity,
): Promise<string> => {
    const formData = createFormData(payload);

    return axios
        .put(`/fidelity/${notice_id}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(() => {
            return Promise.resolve("Mis à jour avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const subscribe = async (
    subscribe: ISubscribeFidelity,
    translate: any,
): Promise<any> => {
    return axios
        .post("/fidelity/subscribe", { ...subscribe })
        .then((res) => {
            return Promise.resolve(translate("Loyalty", res.data.code));
        })
        .catch((err: any) => {
            if (err.response && err.response.data && err.response.data.code) {
                return Promise.reject(
                    translate("Loyalty", err.response.data.code),
                );
            }
            return Promise.reject(err.message);
        });
};

const getUserSwapReduction = async (): Promise<number> => {
    return axios
        .get(`/fidelity/get-swap-reduction`)
        .then(({ data }) => {
            const res: number = data;

            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getUserPurchaseReduction = async (): Promise<number> => {
    return axios
        .get(`/fidelity/get-purchase-reduction`)
        .then(({ data }) => {
            const res: number = data;

            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getUserWithdrawReduction = async (): Promise<WithdrawReduction> => {
    return axios
        .get(`/fidelity/get-withdraw-reduction`)
        .then(({ data }) => {
            const res: WithdrawReduction = data;

            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getLastValidateCard = async (): Promise<FidelityModel | undefined> => {
    return axios
        .get(`/fidelity/last-card`)
        .then(({ data }) => {
            const res: FidelityModel = map(data);

            return res;
        })
        .catch((err: any) => {
            return undefined;
        });
};
const getLastActiveOrderCardRequest = async (): Promise<
    FidelityModel | undefined
> => {
    return axios
        .get(`/fidelity/last-active-order-card-request`)
        .then(({ data }) => {
            const res: FidelityModel = map(data);

            return res;
        })
        .catch((err: any) => {
            return undefined;
        });
};
const getLastValidateCardV2 = async (): Promise<OrderCard | undefined> => {
    return axios
        .get(`/fidelity/last-order-request`)
        .then(({ data }) => {
            const res: OrderCard = mapOrderCard(data);

            return res;
        })
        .catch((err: any) => {
            return undefined;
        });
};

const getOrderCardsDelivered = async (): Promise<OrderCard[] | []> => {
    return axios
        .get(`/fidelity/get-order-cards`)
        .then(({ data }) => {
            // const res: FidelityModel[] = data.map((item: any) => map(item));
            // return res;

            const assets: OrderCard[] = data.map((item: any) =>
                mapOrderCard(item),
            );

            console.log({ assets });

            return assets;
        })
        .catch((err: any) => {
            return [];
            // return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getActiveFidelity = async (): Promise<any | undefined> => {
    return axios
        .get(`/fidelity-subscriptions/get-active`)
        .then(({ data }) => {
            // const res: FidelityModel = map(data);
            const datas = data.plan;
            const res: any = {};
            const fidelity: FidelityModel = map(datas?.fidelity);
            res.type = datas?.type;
            res.id = datas?.id;
            res.price = datas?.price;
            res.name = datas?.name;
            res.fidelity = fidelity;
            res.echeance = datas?.echeance;
            res.subscription = datas?.subscription;
            return res;
        })
        .catch((err: any) => {
            return undefined;
            // return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const stopSubsById = async (id: number, translate: any) => {
    return axios
        .put(`/fidelity-subscriptions/${id}/stop-subs`)
        .then((res) =>
            Promise.resolve(translate("SUBSCRIPTION2", `${res.data.code}`)),
        )
        .catch((err: any) => {
            return Promise.reject(
                UtilsService.getAxiosErrorMessage(
                    translate("SUBSCRIPTION2", `${err.response.data.code}`),
                ),
            );
        });
};
const subscribeCardVirtual = async (
    subscribe: ISubscribeFidelity,
    translate: any,
): Promise<any> => {
    return axios
        .post("/fidelity/subscribe-virtual-card", { ...subscribe })
        .then((res) => {
            return Promise.resolve(translate("Loyalty", res.data.code));
        })
        .catch((err: any) => {
            if (err.response && err.response.data && err.response.data.code) {
                return Promise.reject(
                    translate("VIRTUAL_CARD", err.response.data.code),
                );
            }
            return Promise.reject(err.message);
        });
};
const orderCardLostOrStolen = async (
    payload: ISubscribeFidelity,
    translate: any,
): Promise<any> => {
    return axios
        .post("/fidelity/order-card-lost-stolen", { ...payload })
        .then((res) => {
            return Promise.resolve(
                translate("BACKEND_RESULT_CODE", res.data.code),
            );
        })
        .catch((err: any) => {
            if (err.response && err.response.data && err.response.data.code) {
                return Promise.reject(
                    translate("BACKEND_RESULT_CODE", err.response.data.code),
                );
            }
            return Promise.reject(err.message);
        });
};

const orderCard = async (
    payload: ISubscribeFidelity,
    translate: any,
): Promise<any> => {
    return axios
        .post("/fidelity/order-card", { ...payload })
        .then((res) => {
            return Promise.resolve(
                translate("BACKEND_RESULT_CODE", res.data.code),
            );
            // return Promise.resolve(translate("Loyalty", res.data.code));
        })
        .catch((err: any) => {
            if (err.response && err.response.data && err.response.data.code) {
                return Promise.reject(
                    translate("BACKEND_RESULT_CODE", err.response.data.code),
                );
            }
            return Promise.reject(err.message);
        });
};

const FidelityService = {
    getAll,
    deleteById,
    getById,
    create,
    update,
    getNextLoyalty,
    getLoyaltyData,
    subscribe,
    getUserSwapReduction,
    getUserPurchaseReduction,
    getUserWithdrawReduction,
    getLastLoyalty,
    getLastValidateCard,
    getDataLoyalty,
    getActiveFidelity,
    subscribeCardVirtual,
    getOrderCardsDelivered,
    getLastValidateCardV2,
    stopSubsById,
    orderCardLostOrStolen,
    orderCard,
    getLastActiveOrderCardRequest,
};

export default FidelityService;
