import React, { useState } from "react";
import "react-phone-input-2/lib/bootstrap.css";
import translator from "../../../../Extra/Translation/Translate";
import alert_message from "../../../../../Services/Alert/AlertMessage";
import { useNavigate } from "react-router-dom";
import FidelityService from "../../../../../Services/FidelityService/FidelityService";
import { useFlyoutContext } from "../../../../../App/Provider/FlyOutProvider";
export const StopSubsFlyOutContent = ({
    id,
    reload,
    setReload,
}: {
    id: string;
    reload?: boolean;
    setReload?: (value: boolean) => void;
}) => {
    const { translate } = translator();
    const [processing, setProcessing] = useState<boolean>(false);
    const { setFlyoutOpen, setActiveComponent } = useFlyoutContext();
    const navigate = useNavigate();

    const confirm = () => {
        setProcessing(true);
        try {
            const promise = FidelityService.stopSubsById(Number(id), translate);

            alert_message
                .promise(
                    translate("BACKEND_RESPONSE", "Update_In_Progress"),
                    promise,
                )
                .then(() => {
                    if (setReload) setReload(!reload);
                })
                .finally(() => setProcessing(false));
            setFlyoutOpen(false);
            setActiveComponent(undefined);
        } catch (error) {}
    };

    const changeAbonment = () => {
        navigate("/subscription");
    };
    return (
        <div className="w-full">
            <p className="text-center">
                {translate("SUBSCRIPTION2", "Description_1")}
            </p>
            <p className="block text-center mt-2">
                {translate("SUBSCRIPTION2", "Description_2")}
            </p>

            <div className="flex justify-center mt-4 items-center space-x-2">
                <button
                    onClick={confirm}
                    className="bg-gradient-to-r from-blue-500 to-indigo-800 text-white font-bold py-2 px-4 rounded"
                >
                    {" "}
                    {translate("SUBSCRIPTION2", "Confirm")}
                </button>
                <button
                    onClick={changeAbonment}
                    className="bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-600 "
                >
                    {translate("SUBSCRIPTION2", "Change_Subscription")}
                </button>
            </div>
        </div>
    );
};

export default StopSubsFlyOutContent;
