import { FidelityOrderAccounting } from "../../Models/FidelityOrder";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

const getData = async (): Promise<any> => {
    return axios
        .get(`/comptability`)
        .then((res) => {
            return res.data as any;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getSumFee = async (): Promise<any> => {
    return axios
        .get(`/comptability/get-sum-fee`)
        .then((res) => {
            return res.data as any;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getFidelityOrderSum = async (): Promise<FidelityOrderAccounting[]> => {
    return axios
        .get(`/fidelity-subscription/fidelity-accounting`)
        .then((res) => {
            return res.data as FidelityOrderAccounting[];
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getGeneralReport = async (): Promise<any> => {
    return axios
        .get(`/dashboard/general-report`)
        .then((res) => {
            return res.data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getSumAmountFee = async (): Promise<any> => {
    return axios
        .get(`/dashboard/get-sum-amount-fee`)
        .then((res) => {
            return res.data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const ComptabilityService = {
    getData,
    getFidelityOrderSum,
    getSumFee,
    getGeneralReport,
    getSumAmountFee,
};

export default ComptabilityService;
