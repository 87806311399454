import React, { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
} from "chart.js";
import User from "../../../Models/User";
import UserService from "../../../Services/User/UserService";
import UtilsService from "../../../Services/UtilsService";
import translator from "../../../Components/Extra/Translation/Translate";
import WalletService from "../../../Services/Wallets/WalletService";
import alert_message from "../../../Services/Alert/AlertMessage";
import GlobalBarChart from "./GlobalBarChart";
import TransactionService from "../../../Services/Wallets/TransactionService";
import ComptabilityService from "../../../Services/Comptability/Comptability";
import StackingChartGlobal from "./StackingChartGlobal";
import AdminWalletsAccounting from "../Wallets/AdminWalletsAccounting";
import { UserCryptoWallet } from "../../../Models/UserWallet";
import { useBaseCurrency } from "../../../App/Provider/BaseCurrencyProvider";
import AdminSubscriptionsAccounting from "../Wallets/AdminSubscriptionsAccounting";
import AdminFluxFeesAccounting from "../Wallets/AdminFluxFeesAccounting";
import AdminGeneralReportAccounting from "../Wallets/AdminGeneralReportAccounting";
import AdminLineChart from "./AdminLineChart";
import PnlModel from "../../../Models/Pnl";
import AdminIndividualAccounting from "../Wallets/AdminIndividualAccounting";
import AdminBusinessAccounting from "../Wallets/AdminBusinessAccounting";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
);

interface DateValue {
    startDate: Date;
    endDate: Date;
}

export interface Raport {
    cb: 0;
    sum_amount_kalypay: 0;
    sum_fidelity: 0;
    sum_withdrawal: 0;
    fee_swap: 0;
    swap: 0;
    sum_commission: 0;
    count_user: {
        total: 0;
        active: 0;
    };
}

interface AminLineChartProps {
    pnl: any;
}

const DashboardAdmin: React.FC = () => {
    const { base: baseCurrency } = useBaseCurrency();

    const { translate } = translator();
    const [users, setUsers] = useState<User[]>([]);
    const [cag, setCAG] = useState<number>(0);
    const [userCount, setUserCount] = useState<number>(0);
    const [orderCount, setOrderCount] = useState<number>(0);
    const [totalKNT, setTotalKNT] = useState<number>(0);
    const [generalreport, setGeneralreport] = useState<Raport>();
    const [generalReportData, setGeneralReportData] = useState<any>();
    const [pnlData, setPnlData] = useState<PnlModel>();
    const [pnlYesterdayData, setPnlYesterdayData] = useState<PnlModel>();

    const [user_fiat_wallets, setUserFiatWallets] = useState<
        Array<UserCryptoWallet>
    >([]);

    const [feeData, setFeeData] = useState<any>();
    const [sumFee, setSumFee] = useState<number>();
    const total_benefice = useMemo<string>(() => {
        return UtilsService.formatAmount(
            Number(feeData?.swap) +
                Number(feeData?.withdrawal) +
                Number(feeData?.deposit_kalypay) +
                Number(feeData?.deposit_visa),
            2,
        );
    }, [feeData]);

    const getData = async () => {
        try {
            const response = await ComptabilityService.getData();
            const sumFee = await ComptabilityService.getSumFee();
            setFeeData(response.fees);
            setSumFee(sumFee);
        } catch (err: any) {
            alert_message.error(err);
        }
    };

    const getGeneralData = async () => {
        try {
            const response = await ComptabilityService.getGeneralReport();
            setGeneralReportData(response);
        } catch (err: any) {
            alert_message.error(err);
        }
    };

    const [value, setValue] = useState<DateValue>({
        startDate: new Date(),
        endDate: new Date(),
    });

    const handleValueChange = (newValue: DateValue) => {
        setValue(newValue);
    };

    const getUsers = async () => {
        try {
            const res = await UserService.getTopFive();
            setUsers(res.users);
            setCAG(res.cag);
            setUserCount(res.user_count);
            setOrderCount(res.order_count);
        } catch (err: any) {}
    };

    const getTotalKNT = async () => {
        try {
            const response = await WalletService.getTotalKNT();
            if (response) setTotalKNT(UtilsService.formatDecimal(response, 2));
        } catch (err: any) {
            alert_message.error(err);
        }
    };

    const getGeneralReport = async () => {
        const general_report = await TransactionService.getGeneralReport();
        setGeneralreport(general_report);
    };
    const [totalBonuses, setTotalBonuses] = useState<number | undefined>();
    const getGeneralReportBonusTotal = async () => {
        const res = await TransactionService.getGeneralReportBonusTotal();
        setTotalBonuses(res);
    };

    const [totalCryptoSolde, setTotalCryptoSolde] = useState<number>(0);
    const [totalFiatSolde, setTotalFiatSolde] = useState<number>(0);
    const [totalInvestedCrypto, setTotalInvestedCrypto] = useState<number>(0);
    const [totalInvestedFiat, setTotalInvestedFiat] = useState<number>(0);

    const [totalCoffre, setTotalCoffre] = useState<number>(0);
    const [totalCagnotte, setTotalCagnotte] = useState<number>(0);

    const getUserCryptoWallets = async () => {
        try {
            const response =
                await WalletService.getAllCryptoWithAllUserWallet();

            const crypto_wallets: UserCryptoWallet[] = [];
            const fiat_wallets: UserCryptoWallet[] = [];

            for (const wallet of response.user_wallets) {
                if (wallet.type === "crypto") {
                    crypto_wallets.push(wallet);
                } else {
                    fiat_wallets.push(wallet);
                }
            }

            setTotalCryptoSolde(getTotalSolde(crypto_wallets));
            setTotalInvestedCrypto(getTotalInvestedCrypto(crypto_wallets));
            setTotalFiatSolde(getTotalSolde(fiat_wallets));
            setTotalInvestedFiat(getTotalInvestedCrypto(fiat_wallets));
            setUserFiatWallets(fiat_wallets);
            setTotalCoffre(response?.coffre || 0);
            setTotalCagnotte(response?.cagnotte || 0);
        } catch (err: any) {
            alert_message.error(err);
        }
    };
    const getTotalSolde = (data: UserCryptoWallet[]): number => {
        return data.reduce(
            (sommeTotale, item) =>
                sommeTotale + item.current_price * item.wallet.solde,
            0,
        );
    };

    const getTotalInvestedCrypto = (data: UserCryptoWallet[]): number => {
        return data.reduce(
            (sommeTotale, item) => sommeTotale + item.wallet.staking_usd_price,
            0,
        );
    };

    useEffect(() => {
        getUsers();
        getTotalKNT();
        getGeneralReport();
        getGeneralReportBonusTotal();
        // getData();
        getGeneralData();
        getUserCryptoWallets();
    }, []);

    return (
        <div>
            <div className="mt-10 md:!mt-7 grid grid-cols-12 gap-6">
                <div className="col-span-12 2xl:col-span-9">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12">
                            <AdminGeneralReportAccounting />
                        </div>
                        {/* Evolution des ventes */}
                        <AdminLineChart pnl={pnlData}></AdminLineChart>
                        {/* Rapport des ventes */}
                        <GlobalBarChart pnl={pnlData}></GlobalBarChart>
                        <div className="col-span-12 -mt-5">
                            <AdminSubscriptionsAccounting />
                        </div>
                        <div className="col-span-12">
                            <AdminIndividualAccounting />
                        </div>
                        <div className="col-span-12">
                            <AdminBusinessAccounting />
                        </div>
                        <div className="col-span-12">
                            <AdminFluxFeesAccounting />
                        </div>
                    </div>
                </div>

                <div className="col-span-12 2xl:col-span-3">
                    <div className=" 2xl:border-l">
                        <div className="grid grid-cols-12 gap-6 2xl:gap-x-0 2xl:pl-6">
                            <div className="box pb-3 col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-12 xl:mt-12 dark:!border-2 dark:!border-slate-700 dark:!rounded-md">
                                <div className="report-box pr-5 pl-5 pt-2 pb-2">
                                    <div className="grid grid-cols-2">
                                        <div className="ml-auto"></div>
                                    </div>
                                    <div className="mt-2 text-lg font-bold flex justify-left items-center">
                                        {translate(
                                            "ACCOUNTING",
                                            "Users_Statistics",
                                        )}
                                    </div>
                                    <div className="mt-5 text-xl font-medium flex justify-between items-left">
                                        <div className="text-lg font-medium">
                                            {translate("APP", "Number_users")}:{" "}
                                            {generalreport
                                                ? generalreport.count_user.total
                                                : "-"}
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-left">
                                        <div className="text-base font-medium text-slate-500">
                                            {translate(
                                                "Admin_Dashboard",
                                                "Active",
                                            )}{" "}
                                            {generalreport
                                                ? generalreport.count_user
                                                      .active
                                                : "-"}{" "}
                                            /{" "}
                                            {generalreport
                                                ? generalreport.count_user
                                                      .total -
                                                  generalreport.count_user
                                                      .active
                                                : "-"}{" "}
                                            {translate(
                                                "Admin_Dashboard",
                                                "Inactive",
                                            )}
                                        </div>
                                        <div
                                            className="report-box__indicator bg-green-600 tooltip cursor-pointer"
                                            title=""
                                        >
                                            {generalreport
                                                ? UtilsService.getPercentValue(
                                                      generalreport.count_user
                                                          .active,
                                                      generalreport.count_user
                                                          .total,
                                                  )
                                                : "-"}
                                            %
                                            <i
                                                data-lucide="chevron-up"
                                                className="ml-0.5 h-4 w-1"
                                            ></i>
                                        </div>
                                    </div>

                                    <div className="mt-5 text-xl font-medium flex justify-between items-left">
                                        <div className="text-lg font-medium">
                                            {translate(
                                                "HOME_PAGE",
                                                "Individuals",
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-left">
                                        <div className="text-base text-slate-500">
                                            {translate(
                                                "Admin_Dashboard",
                                                "Active",
                                            )}{" "}
                                            {generalreport
                                                ? generalreport.count_user
                                                      .active
                                                : "-"}{" "}
                                            /{" "}
                                            {generalreport
                                                ? generalreport.count_user
                                                      .total -
                                                  generalreport.count_user
                                                      .active
                                                : "-"}{" "}
                                            {translate(
                                                "Admin_Dashboard",
                                                "Inactive",
                                            )}
                                        </div>
                                        <div
                                            className="report-box__indicator bg-green-600 tooltip cursor-pointer"
                                            title=""
                                        >
                                            {generalreport
                                                ? UtilsService.getPercentValue(
                                                      generalreport.count_user
                                                          .active,
                                                      generalreport.count_user
                                                          .total,
                                                  )
                                                : "-"}
                                            %
                                            <i
                                                data-lucide="chevron-up"
                                                className="ml-0.5 h-4 w-1"
                                            ></i>
                                        </div>
                                    </div>
                                    <div className="mt-5 text-xl font-medium flex justify-between items-left">
                                        <div className="text-lg font-medium">
                                            {translate(
                                                "ACCOUNTING",
                                                "Business",
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex justify-between items-left">
                                        <div className="text-base text-slate-500">
                                            {translate(
                                                "Admin_Dashboard",
                                                "Active",
                                            )}{" "}
                                            {generalreport
                                                ? generalreport.count_user
                                                      .active
                                                : "-"}{" "}
                                            /{" "}
                                            {generalreport
                                                ? generalreport.count_user
                                                      .total -
                                                  generalreport.count_user
                                                      .active
                                                : "-"}{" "}
                                            {translate(
                                                "Admin_Dashboard",
                                                "Inactive",
                                            )}
                                        </div>
                                        <div
                                            className="report-box__indicator bg-green-600 tooltip cursor-pointer"
                                            title=""
                                        >
                                            {generalreport
                                                ? UtilsService.getPercentValue(
                                                      generalreport.count_user
                                                          .active,
                                                      generalreport.count_user
                                                          .total,
                                                  )
                                                : "-"}
                                            %
                                            <i
                                                data-lucide="chevron-up"
                                                className="ml-0.5 h-4 w-1"
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-span-12 !mt-[1.75rem] md:col-span-6 xl:col-span-4 2xl:col-span-12 p-4 ml-1">
                            <div className="intro-x flex h-10 items-center">
                                <h2 className="truncate text-lg font-medium">
                                    Top 5{" "}
                                    {translate("Admin_Dashboard", "Users")}
                                </h2>
                            </div>
                            <div className="mt-2">
                                {users.map((res: User, key: number) => (
                                    <div className="intro-x" key={key}>
                                        <div className="box mb-3 flex items-center px-5 py-3 dark:!border-2 dark:!border-slate-700 dark:!rounded-md">
                                            <div className="image-fit h-10 w-10 flex-none overflow-hidden rounded-full">
                                                <img
                                                    alt="image"
                                                    src={UtilsService.getProfilPicsUrl(
                                                        res,
                                                    )}
                                                />
                                            </div>
                                            <div className="ml-4 mr-auto">
                                                <div className="font-medium">
                                                    {`${res.first_name} ${res.last_name}`}
                                                </div>
                                                {res.created_at && (
                                                    <div className="mt-0.5 text-xs text-slate-500">
                                                        {UtilsService.getBeautifulFormatedDateWithTime(
                                                            res.created_at,
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="text-green-600">
                                                +{res.cap}€
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-12">
                    <AdminWalletsAccounting />
                </div>
                <div className="col-span-5">
                    {/* Répartition des actifs */}
                    <StackingChartGlobal />
                </div>
            </div>
        </div>
    );
};

export default DashboardAdmin;
