import React, { useEffect, useState } from "react";
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    RadialLinearScale,
    Title,
    Tooltip,
} from "chart.js";
import TransactionService from "../../../Services/Wallets/TransactionService";
import ApiLineChart from "./ApiLineChart";
import ApiSalesBarChart from "./ApiSalesBarChart";
import ApiGeneralReport from "../Wallets/ApiGeneralReport";
import alert_message from "../../../Services/Alert/AlertMessage";
import { AmountData, TypeDateAmount } from "../../../Models/Transaction";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
);
const InfinexiaApi: React.FC = () => {
    const [generalReportData, setGeneralReportData] = useState<AmountData>();
    const [groupData, setGroupData] = useState<TypeDateAmount>();
    const getGroupAmountData = async () => {
        try {
            const response =
                await TransactionService.getAmountHistoryDataGroup();
            setGroupData(response);
        } catch (err: any) {
            alert_message.error(err);
        }
    };

    const getGeneralData = async () => {
        try {
            const response = await TransactionService.getSumAmountUsdByUser();
            setGeneralReportData(response as AmountData);
        } catch (err: any) {
            alert_message.error(err);
        }
    };
    useEffect(() => {
        getGroupAmountData();
        getGeneralData();
    }, []);
    return (
        <>
            <div className="">
                <div className="col-span-12 py-5 px-3 rounded-b-lg dark:!border-2 dark:!border-slate-700">
                    <div className="col-span-12 2xl:col-span-9 md:!px-8 py-3 pb-5">
                        <div className="grid grid-cols-12 gap-6 ">
                            <div className="col-span-12">
                                {generalReportData && (
                                    <ApiGeneralReport
                                        generalReportData={generalReportData}
                                    />
                                )}
                            </div>
                            {/* Evolution des comptes */}
                            <ApiLineChart dataApi={groupData}></ApiLineChart>
                            {/* Rapport des ventes */}
                            <ApiSalesBarChart
                                dataApi={groupData}
                            ></ApiSalesBarChart>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InfinexiaApi;
