import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
} from "chart.js";
import translator from "../../../Components/Extra/Translation/Translate";
import alert_message from "../../../Services/Alert/AlertMessage";
import ComptabilityService from "../../../Services/Comptability/Comptability";
import { useBaseCurrency } from "../../../App/Provider/BaseCurrencyProvider";
import AdminGeneralReportAccounting from "./AdminGeneralReportAccounting";
import AdminSubscriptionsAccounting from "./AdminSubscriptionsAccounting";
import AdminFluxFeesAccounting from "./AdminFluxFeesAccounting";
import AdminWalletsAccounting from "./AdminWalletsAccounting";
import AdminBusinessAccounting from "./AdminBusinessAccounting";
import AdminIndividualAccounting from "./AdminIndividualAccounting";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
);

export interface Raport {
    cb: 0;
    sum_amount_kalypay: 0;
    sum_fidelity: 0;
    sum_withdrawal: 0;
    fee_swap: 0;
    swap: 0;
    sum_commission: 0;
    count_user: {
        total: 0;
        active: 0;
    };
}
const AdminCompta: React.FC = () => {
    const { translate } = translator();
    const { base: baseCurrency } = useBaseCurrency();

    const [amountFeeData, setAmountFeeData] = useState<any>(null);
    const getSumAmountFeeData = async () => {
        try {
            const response = await ComptabilityService.getSumAmountFee();
            setAmountFeeData(response);
        } catch (err: any) {
            alert_message.error(err);
        }
    };

    useEffect(() => {
        getSumAmountFeeData();
    }, []);
    return (
        <div>
            <div className="intro-y mt-10 md:!mt-7 mb-2 items-center mx-0 px-0 grid grid-cols-12 gap-6">
                <div className="col-span-12 2xl:col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12">
                            <div className="col-span-12">
                                <AdminGeneralReportAccounting />
                            </div>
                            <div className="col-span-12">
                                <AdminSubscriptionsAccounting />
                            </div>
                            <div className="col-span-12">
                                <AdminFluxFeesAccounting />
                            </div>
                            <div className="col-span-12">
                                <AdminIndividualAccounting />
                            </div>
                            <div className="col-span-12">
                                <AdminBusinessAccounting />
                            </div>
                            <div className="col-span-12">
                                <AdminWalletsAccounting />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminCompta;
