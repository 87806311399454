import React, { useState } from "react";
import UtilsService from "../../../Services/UtilsService";
import { Raport } from "./DashboardAdmin";
import translator from "../../../Components/Extra/Translation/Translate";

const CardInfo = ({
    data,
    label,
    isFee,
    baseCurrency,
}: {
    data: any;
    label: string;
    isFee: boolean;
    baseCurrency?: any;
}) => {
    const { translate } = translator();
    const [generalreport, setGeneralreport] = useState<Raport>();
    const [generalReportData, setGeneralReportData] = useState<any>();
    return isFee ? (
        <div className="intro-y col-span-12 sm:col-span-6 xl:col-span-3 h-full">
            <div className="report-box h-full dark:border-2 dark:border-slate-700 dark:rounded-md">
                <div className="box p-5 h-full">
                    <div className="flex">
                        <span className="text-base">{label}</span>
                        <div className="ml-auto"></div>
                    </div>
                    <div className="mt-6  font-medium leading-8">
                        <span>
                            {" "}
                            {translate("Checkout_API", "Montant")}{" "}
                            {/* {data
                                ? UtilsService.formatAmount(data.amount, 2)
                                : "-"}
                            $ */}
                            {baseCurrency
                                ? UtilsService.getConvertedFormatedAmount(
                                      data.amount,
                                      baseCurrency,
                                      2,
                                  )
                                : ""}
                        </span>{" "}
                        <br />
                        <span>
                            {" "}
                            {translate(
                                "Admin_Withdrawal_Request",
                                "Fee",
                            )} :{" "}
                            {/* {data
                                ? UtilsService.formatAmount(data.fee, 2)
                                : "-"}
                            $ */}
                            {baseCurrency
                                ? UtilsService.getConvertedFormatedAmount(
                                      data.fee,
                                      baseCurrency,
                                      2,
                                  )
                                : ""}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <>
            <div className="intro-y col-span-12 sm:col-span-6 xl:col-span-3 h-full">
                <div className="report-box h-full dark:border-2 dark:border-slate-700 dark:rounded-md">
                    <div className="box p-5 h-full">
                        <div className="flex justify-between items-left">
                            <span className="text-base"> Blue </span>
                            <div className="ml-auto"></div>
                            <div
                                className="report-box__indicator bg-green-600 tooltip cursor-pointer"
                                title=""
                            >
                                {generalreport
                                    ? UtilsService.getPercentValue(
                                          generalreport.count_user.active,
                                          generalreport.count_user.total,
                                      )
                                    : "-"}
                                %
                                <i
                                    data-lucide="chevron-up"
                                    className="ml-0.5 h-4 w-1"
                                ></i>
                            </div>
                        </div>
                        <div className="mt-6 font-medium leading-8">
                            <span>
                                {" "}
                                {translate("Checkout_API", "Montant")}{" "}
                                {/* {data
                                    ? UtilsService.formatAmount(
                                          data.blue.amount,
                                          2,
                                      )
                                    : "-"}
                                $ */}
                                {baseCurrency
                                    ? UtilsService.getConvertedFormatedAmount(
                                          data.blue.amount,
                                          baseCurrency,
                                          2,
                                      )
                                    : ""}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6 xl:col-span-3 h-full">
                <div className="report-box h-full dark:border-2 dark:border-slate-700 dark:rounded-md">
                    <div className="box p-5 h-full">
                        <div className="flex">
                            <span className="text-base"> Gold </span>
                            <div className="ml-auto"></div>
                            <div
                                className="report-box__indicator bg-green-600 tooltip cursor-pointer"
                                title=""
                            >
                                {generalreport
                                    ? UtilsService.getPercentValue(
                                          generalreport.count_user.active,
                                          generalreport.count_user.total,
                                      )
                                    : "-"}
                                %
                                <i
                                    data-lucide="chevron-up"
                                    className="ml-0.5 h-4 w-1"
                                ></i>
                            </div>
                        </div>
                        <div className="mt-6 font-medium leading-8">
                            <span>
                                {" "}
                                {translate("Checkout_API", "Montant")}{" "}
                                {/* {data
                                    ? UtilsService.formatAmount(
                                          data.gold.amount,
                                          2,
                                      )
                                    : "-"}
                                $ */}
                                {baseCurrency
                                    ? UtilsService.getConvertedFormatedAmount(
                                          data.gold.amount,
                                          baseCurrency,
                                          2,
                                      )
                                    : ""}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6 xl:col-span-3 h-full">
                <div className="report-box h-full dark:border-2 dark:border-slate-700 dark:rounded-md">
                    <div className="box p-5 h-full">
                        <div className="flex">
                            <span className="text-base"> Black </span>
                            <div className="ml-auto"></div>
                            <div
                                className="report-box__indicator bg-green-600 tooltip cursor-pointer"
                                title=""
                            >
                                {generalreport
                                    ? UtilsService.getPercentValue(
                                          generalreport.count_user.active,
                                          generalreport.count_user.total,
                                      )
                                    : "-"}
                                %
                                <i
                                    data-lucide="chevron-up"
                                    className="ml-0.5 h-4 w-1"
                                ></i>
                            </div>
                        </div>
                        <div className="mt-6 font-medium leading-8">
                            <span>
                                {" "}
                                {translate("Checkout_API", "Montant")}{" "}
                                {/* {baseCurrency
                                    ? UtilsService.formatAmount(
                                          data.black.amount,
                                          2,
                                      )
                                    : ""} */}
                                {baseCurrency
                                    ? UtilsService.getConvertedFormatedAmount(
                                          data.black.amount,
                                          baseCurrency,
                                          2,
                                      )
                                    : ""}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default CardInfo;
