export const barOptions = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: "bottom" as const,
        },
        title: {
            display: true,
            text: "",
        },
    },
};

export const chartOtherOptions = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: "bottom" as const,
        },
        title: {
            display: false,
            text: "Chart.js Bar Chart",
        },
    },
};
export const barLabels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const barData = {
    labels: barLabels,
    datasets: [
        {
            label: "PackBooster 1",
            data: [
                30000, 50000, 15000, 25000, 30000, 20000, 15000, 50000, 15000,
                25000, 30000, 20000,
            ],
            backgroundColor: "#053B3B",
        },
        {
            label: "PackBooster 2",
            data: [
                10000, 12000, 9000, 22000, 8000, 9000, 12000, 9000, 22000, 8000,
                9000, 12000, 9000,
            ],
            backgroundColor: "#3695D2",
        },
    ],
};

export const pieData = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
        {
            label: "# of Votes",
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                "#053B3B",
                "#6130AA",
                "#3695D2",
                "#8A1823",
                "#F5B221",
                "#551830",
            ],
            // borderColor: [
            //     "rgba(255, 99, 132, 1)",
            //     "rgba(54, 162, 235, 1)",
            //     "rgba(255, 206, 86, 1)",
            //     "rgba(75, 192, 192, 1)",
            //     "rgba(153, 102, 255, 1)",
            //     "rgba(255, 159, 64, 1)",
            // ],
            borderWidth: 1,
        },
    ],
};

export const radarData = {
    labels: ["Thing 1", "Thing 2", "Thing 3", "Thing 4", "Thing 5", "Thing 6"],
    datasets: [
        {
            label: "# of Votes",
            data: [2, 4, 3, 5, 2, 3],
            backgroundColor: "#3695D2",
            borderColor: "#053B3B",
            borderWidth: 1,
        },
    ],
};

export const DoughnutData = {
    labels: ["fiat", "crypto", "coffre", "jackpot", "saving"],
    datasets: [
        {
            label: "# of Votes",
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                "#053B3B",
                "#6130AA",
                "#3695D2",
                "#8A1823",
                "#F5B221",
                "#551830",
            ],
            // cutout: "80%",
            // borderColor: "rgba(255, 255, 255, 0)",
            borderWidth: 4,
        },
    ],
};

export const doughnutOptions = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: "bottom" as const,
        },
        title: {
            display: false,
            text: "Chart.js Doughnut Chart",
        },
    },
};
