import React, { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
} from "chart.js";
import User from "../../../Models/User";
import UserService from "../../../Services/User/UserService";
import UtilsService from "../../../Services/UtilsService";
import translator from "../../../Components/Extra/Translation/Translate";
import WalletService from "../../../Services/Wallets/WalletService";
import alert_message from "../../../Services/Alert/AlertMessage";
import TransactionService from "../../../Services/Wallets/TransactionService";
import ComptabilityService from "../../../Services/Comptability/Comptability";
import { useBaseCurrency } from "../../../App/Provider/BaseCurrencyProvider";
import { UserCryptoWallet } from "../../../Models/UserWallet";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
);

interface DateValue {
    startDate: Date;
    endDate: Date;
}

export interface Raport {
    cb: 0;
    sum_amount_kalypay: 0;
    sum_fidelity: 0;
    sum_withdrawal: 0;
    fee_swap: 0;
    swap: 0;
    sum_commission: 0;
    count_user: {
        total: 0;
        active: 0;
    };
}

const AdminGeneralReportAccounting: React.FC = () => {
    const { base: baseCurrency } = useBaseCurrency();

    const { translate } = translator();
    const [users, setUsers] = useState<User[]>([]);
    const [cag, setCAG] = useState<number>(0);
    const [userCount, setUserCount] = useState<number>(0);
    const [orderCount, setOrderCount] = useState<number>(0);
    const [totalKNT, setTotalKNT] = useState<number>(0);
    const [generalreport, setGeneralreport] = useState<Raport>();
    const [generalReportData, setGeneralReportData] = useState<any>();

    const [user_fiat_wallets, setUserFiatWallets] = useState<
        Array<UserCryptoWallet>
    >([]);

    const [feeData, setFeeData] = useState<any>();
    const [sumFee, setSumFee] = useState<number>();
    const total_benefice = useMemo<string>(() => {
        return UtilsService.formatAmount(
            Number(feeData?.swap) +
                Number(feeData?.withdrawal) +
                Number(feeData?.deposit_kalypay) +
                Number(feeData?.deposit_visa),
            2,
        );
    }, [feeData]);

    const getData = async () => {
        try {
            const response = await ComptabilityService.getData();
            const sumFee = await ComptabilityService.getSumFee();
            setFeeData(response.fees);
            setSumFee(sumFee);
        } catch (err: any) {
            alert_message.error(err);
        }
    };

    const getGeneralData = async () => {
        try {
            const response = await ComptabilityService.getGeneralReport();
            setGeneralReportData(response);
        } catch (err: any) {
            alert_message.error(err);
        }
    };

    const [value, setValue] = useState<DateValue>({
        startDate: new Date(),
        endDate: new Date(),
    });

    const handleValueChange = (newValue: DateValue) => {
        setValue(newValue);
    };

    const getUsers = async () => {
        try {
            const res = await UserService.getTopFive();
            setUsers(res.users);
            setCAG(res.cag);
            setUserCount(res.user_count);
            setOrderCount(res.order_count);
        } catch (err: any) {}
    };

    const getTotalKNT = async () => {
        try {
            const response = await WalletService.getTotalKNT();
            if (response) setTotalKNT(UtilsService.formatDecimal(response, 2));
        } catch (err: any) {
            alert_message.error(err);
        }
    };

    const getGeneralReport = async () => {
        const general_report = await TransactionService.getGeneralReport();
        setGeneralreport(general_report);
    };
    const [totalBonuses, setTotalBonuses] = useState<number | undefined>();
    const getGeneralReportBonusTotal = async () => {
        const res = await TransactionService.getGeneralReportBonusTotal();
        setTotalBonuses(res);
    };

    const [totalCryptoSolde, setTotalCryptoSolde] = useState<number>(0);
    const [totalFiatSolde, setTotalFiatSolde] = useState<number>(0);
    const [totalInvestedCrypto, setTotalInvestedCrypto] = useState<number>(0);
    const [totalInvestedFiat, setTotalInvestedFiat] = useState<number>(0);

    const [totalCoffre, setTotalCoffre] = useState<number>(0);
    const [totalCagnotte, setTotalCagnotte] = useState<number>(0);

    const getUserCryptoWallets = async () => {
        try {
            const response =
                await WalletService.getAllCryptoWithAllUserWallet();

            const crypto_wallets: UserCryptoWallet[] = [];
            const fiat_wallets: UserCryptoWallet[] = [];

            for (const wallet of response.user_wallets) {
                if (wallet.type === "crypto") {
                    crypto_wallets.push(wallet);
                } else {
                    fiat_wallets.push(wallet);
                }
            }

            setTotalCryptoSolde(getTotalSolde(crypto_wallets));
            setTotalInvestedCrypto(getTotalInvestedCrypto(crypto_wallets));
            setTotalFiatSolde(getTotalSolde(fiat_wallets));
            setTotalInvestedFiat(getTotalInvestedCrypto(fiat_wallets));
            setUserFiatWallets(fiat_wallets);
            setTotalCoffre(response?.coffre || 0);
            setTotalCagnotte(response?.cagnotte || 0);
        } catch (err: any) {
            alert_message.error(err);
        }
    };
    const getTotalSolde = (data: UserCryptoWallet[]): number => {
        return data.reduce(
            (sommeTotale, item) =>
                sommeTotale + item.current_price * item.wallet.solde,
            0,
        );
    };

    const getTotalInvestedCrypto = (data: UserCryptoWallet[]): number => {
        return data.reduce(
            (sommeTotale, item) => sommeTotale + item.wallet.staking_usd_price,
            0,
        );
    };

    useEffect(() => {
        getUsers();
        getTotalKNT();
        getGeneralReport();
        getGeneralReportBonusTotal();
        // getData();
        getGeneralData();
        getUserCryptoWallets();
    }, []);
    return (
        <div>
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 2xl:col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12">
                            <div className="intro-y flex h-10 items-center">
                                <h2 className="mr-5 truncate text-lg font-medium">
                                    {translate(
                                        "Admin_Dashboard",
                                        "General_Report",
                                    )}
                                </h2>
                            </div>
                            <div className="mt-1 grid grid-cols-12 gap-6">
                                {/* Total Ventes */}
                                <div className="intro-y col-span-12 sm:col-span-6 xl:col-span-3">
                                    <div className="report-box dark:border-2 dark:border-slate-700 dark:rounded-md">
                                        <div className="box p-5">
                                            <div className="mt-6 text-3xl font-medium leading-8">
                                                {generalReportData &&
                                                baseCurrency
                                                    ? UtilsService.getConvertedFormatedAmount(
                                                          generalReportData.vente,
                                                          baseCurrency,
                                                          2,
                                                      )
                                                    : ""}
                                            </div>
                                            <div className="mt-1 text-base text-slate-500">
                                                {translate(
                                                    "Admin_Dashboard",
                                                    "Total_Sales",
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Total Chiffre d'affaire */}
                                <div className="intro-y col-span-12 sm:col-span-6 xl:col-span-3">
                                    <div className="report-box dark:border-2 dark:border-slate-700 dark:rounded-md">
                                        <div className="box p-5">
                                            <div className="mt-6 text-3xl font-medium leading-8">
                                                {generalReportData &&
                                                baseCurrency
                                                    ? UtilsService.getConvertedFormatedAmount(
                                                          generalReportData.ca,
                                                          baseCurrency,
                                                          2,
                                                      )
                                                    : ""}
                                            </div>
                                            <div className="mt-1 text-base text-slate-500">
                                                {translate(
                                                    "Admin_Dashboard",
                                                    "Total_Turnover",
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Total Bénéfices */}
                                <div className="intro-y col-span-12 sm:col-span-6 xl:col-span-3">
                                    <div className="report-box dark:border-2 dark:border-slate-700 dark:rounded-md">
                                        <div className="box p-5">
                                            <div className="mt-6 text-3xl font-medium leading-8">
                                                {generalReportData &&
                                                baseCurrency
                                                    ? UtilsService.getConvertedFormatedAmount(
                                                          generalReportData.benefice,
                                                          baseCurrency,
                                                          2,
                                                      )
                                                    : ""}
                                            </div>
                                            <div className="mt-1 text-base text-slate-500">
                                                {translate(
                                                    "Admin_Dashboard",
                                                    "Total_Profits",
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Commissions */}
                                <div className="intro-y col-span-12 sm:col-span-6 xl:col-span-3">
                                    <div className="report-box dark:border-2 dark:border-slate-700 dark:rounded-md">
                                        <div className="box p-5">
                                            <div className="mt-6 text-3xl font-medium leading-8">
                                                {generalReportData &&
                                                baseCurrency
                                                    ? UtilsService.getConvertedFormatedAmount(
                                                          generalReportData
                                                              .commission
                                                              ?.total,
                                                          baseCurrency,
                                                          2,
                                                      )
                                                    : ""}
                                            </div>
                                            <div className="mt-1 text-base text-slate-500">
                                                {translate(
                                                    "Admin_Dashboard",
                                                    "Total_Commissions",
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminGeneralReportAccounting;
