import React, { useEffect, useMemo, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { NavLink } from "react-router-dom";
// import Datepicker from "react-tailwindcss-datepicker";
import translator from "../../../Components/Extra/Translation/Translate";
import { barOptions } from "./ChartData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TransactionService from "../../../Services/Wallets/TransactionService";
import UtilsService from "../../../Services/UtilsService";
import { useBaseCurrency } from "../../../App/Provider/BaseCurrencyProvider";
import useLanguage from "../../../Services/Hooks/useLanguage";
import { Option } from "react-tailwindcss-select/dist/components/type";
import SelectInput from "../../../Components/Extra/Input/SelectInput";

interface DateValue {
    startDate: Date;
    endDate: Date;
}

interface GlobalBarChartProps {
    pnl: any;
    width?: number;
    height?: number;
    isdefault?: boolean;
}

export default function GlobalBarChart({
    pnl,
    width,
    height,
    isdefault,
}: GlobalBarChartProps) {
    const { base: baseCurrency } = useBaseCurrency();
    const { translate } = translator();
    const canvas = useRef<HTMLCanvasElement>(null);
    const { language } = useLanguage();

    const [value, setValue] = useState<DateValue>({
        startDate: new Date(),
        endDate: new Date(),
    });

    const handleValueChange = (newValue: DateValue) => {
        setValue(newValue);
    };

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const handleStartDateChange = (date: Date | null) => {
        setStartDate(date);
        // sendDatesRanges();
    };

    const handleEndDateChange = (date: Date | null) => {
        setEndDate(date);
        // sendDatesRanges();
    };

    // const sendDatesRanges = (): void => {};

    const [allCB, setAllCB] = useState<any>();
    const [monthlyCBData, setMonthlyCBData] = useState<number[]>([]);
    const [lastMonthCB, setLastMonthDB] = useState<number | undefined>();

    const [filter, setFilter] = useState<string>("day");
    const [filterOption, setFilterOption] = useState<Option>({
        label: translate("WalletLineChart", "Days"),
        value: "day",
    });
    const filterOptions = useMemo<Option[]>(() => {
        const filterOptions: Option[] = [
            {
                label: translate("WalletLineChart", "Day"),
                value: "day",
            },
            {
                label: translate("WalletLineChart", "Week"),
                value: "week",
            },
            {
                label: translate("WalletLineChart", "Month"),
                value: "month",
            },
            {
                label: translate("WalletLineChart", "Year"),
                value: "year",
            },
            {
                label: translate("WalletLineChart", "All"),
                value: "all",
            },
        ];

        return filterOptions;
    }, [pnl, filter, language]);

    const data = useMemo<any[]>(() => {
        let data1 = [];
        if (pnl) {
            switch (filter) {
                case "day":
                    data1 = pnl.pnls ? pnl.pnls.days : [];
                    break;
                case "week":
                    data1 = pnl.pnls ? pnl.pnls.week : [];
                    break;
                case "month":
                    data1 = pnl.pnls ? pnl.pnls.month : [];
                    break;
                case "trimester":
                    data1 = pnl.pnls ? pnl.pnls.trimester : [];
                    break;
                case "year":
                    data1 = pnl.pnls ? pnl.pnls.year : [];
                    break;
                default:
                    data1 = pnl.data;
                    break;
            }
        }

        return data1;
    }, [pnl, filter, language]);

    useEffect(() => {
        const fnd_opton = filterOptions.find(
            (opt) => opt.value === filterOption.value,
        );
        if (fnd_opton) setFilterOption(fnd_opton);
    }, [filterOptions]);

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();

    const monthLabels = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const lastMonthTotalCBUSDAmount = async () => {
        try {
            const res = await TransactionService.lastMonthTotalCBUSDAmount();
            setLastMonthDB(res);
        } catch (error) {}
    };
    const monthlyTotalCBUSDAmount = async () => {
        try {
            const res = await TransactionService.monthlyTotalCBUSDAmount();
            const data = monthLabels.map((month, index) => {
                const item = res.find(
                    (el: any) => Number(el.month.slice(-2)) === index + 1,
                );
                return item ? item.total_amount : 0;
            });
            setMonthlyCBData(data);

            setAllCB({
                labels: monthLabels,
                datasets: [
                    {
                        label: "Total",
                        data: data,
                        backgroundColor: "#3695D2",
                    },
                ],
            });
        } catch (error) {}
    };

    useEffect(() => {
        monthlyTotalCBUSDAmount();
        lastMonthTotalCBUSDAmount();
    }, []);
    return (
        <div className="col-span-12 lg:col-span-6">
            <div className="flex w-full flex-col col-span-full h-full box sm:col-span-12 bg-white dark:bg-slate-800 shadow-xl rounded-sm border dark:!border-2 dark:!border-slate-700">
                <header className="sm:!px-2 md:!px-5 py-1 border-b border-[#d0d0d0] h-[55px] flex justify-between items-center dark:!border-b dark:!border-slate-700">
                    <h2 className="font-semibold text-left">
                        {translate("Admin_Dashboard", "Sales_Report")}
                    </h2>
                    <div className="w-[150px]">
                        <SelectInput
                            options={filterOptions}
                            value={filterOption}
                            minHeight={35}
                            isSearchable={false}
                            onChange={(value: any) => {
                                setFilterOption(value);
                                setFilter(value.value);
                            }}
                        />
                    </div>
                </header>
                <div className="flex flex-col md:flex-row md:items-center py-3 px-5">
                    <div className="flex">
                        <div>
                            <div className="text-primary text-2xl font-bold dark:text-slate-300">
                                {/* $
                                {monthlyCBData.length == 12
                                    ? UtilsService.formatAmount(
                                          monthlyCBData[currentMonth],
                                          2,
                                      )
                                    : "0"} */}
                                {monthlyCBData.length == 12 && baseCurrency
                                    ? UtilsService.getConvertedFormatedAmount(
                                          monthlyCBData[currentMonth],
                                          baseCurrency,
                                          2,
                                      )
                                    : baseCurrency
                                      ? UtilsService.getConvertedFormatedAmount(
                                            0,
                                            baseCurrency,
                                            2,
                                        )
                                      : ""}
                            </div>
                            <div className="mt-0.5 text-slate-500">
                                {translate("Admin_Dashboard", "This_Month")}
                            </div>
                        </div>
                        <div className="dark:border-darkmode-300 mx-4 h-12 w-px border border-dashed border-slate-200 xl:mx-5"></div>
                        <div>
                            <div className="text-2xl font-bold text-slate-500 ">
                                {/* $
                                {lastMonthCB
                                    ? UtilsService.formatAmount(lastMonthCB, 2)
                                    : "0"} */}
                                {lastMonthCB && baseCurrency
                                    ? UtilsService.getConvertedFormatedAmount(
                                          lastMonthCB,
                                          baseCurrency,
                                          2,
                                      )
                                    : baseCurrency
                                      ? UtilsService.getConvertedFormatedAmount(
                                            0,
                                            baseCurrency,
                                            2,
                                        )
                                      : ""}
                            </div>
                            <div className="mt-0.5 text-slate-500">
                                {translate("Admin_Dashboard", "Last_Month")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-chart">
                    {allCB && (
                        <div className="w-full sm:!px-3 md:!px-5 py-1 h-[300px]">
                            <Bar options={barOptions} data={allCB} />
                        </div>
                    )}
                </div>
                {/* Chart built with Chart.js 3 */}
                <div
                    className={isdefault == true ? "blur-sm grow" : "grow"}
                ></div>
                {isdefault && (
                    <span className="absolute z-1 top-[50%] w-full flex justify-center text-center pr-[25px] pl-[25px]">
                        {translate(
                            "Dashboard",
                            "Insufficient_data_display_graph",
                        )}
                    </span>
                )}
            </div>
        </div>
    );
}
