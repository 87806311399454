import React, { useEffect, useMemo, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { doughnutOptions } from "../../../Admin/Components/Dashboard/ChartData";
import StackingChartData from "../../../Models/StackingChartData";
import alert_message from "../../../Services/Alert/AlertMessage";
import useTheme from "../../../Services/Hooks/useTheme";
import { AppMode } from "../../../globalState/reducers/Theme.reducer";
import { ActifRepartition } from "../../../Models/Pnl";
import translator from "../../../Components/Extra/Translation/Translate";
import PnlService from "../../../Services/Pnl/PnlService";

const StackingChartGlobal: React.FC = () => {
    const { translate } = translator();
    const { mode } = useTheme();
    const newTheme = mode === AppMode.LIGHT ? AppMode.DARK : AppMode.LIGHT;
    const isDark = newTheme === AppMode.DARK;

    const [repartition, setRepartition] = useState<ActifRepartition>({
        earning: 0,
        crypto: 0,
        coffre: 0,
        cagnotte: 0,
        fiat: 0,
    });

    const [doughnutProcessing, setDoughnutProcessing] = useState<
        boolean | undefined
    >();

    const [stackingChartData, setStackingChartData] = useState<
        Array<StackingChartData>
    >([]);

    const labels = [
        translate("Earning", "Fiat"),
        translate("Earning", "Crypto"),
        translate("Earning", "Safe"),
        translate("Earning", "Jackpot"),
        translate("TransactionType", "staking"),
    ];

    const reparition_amount = [
        repartition.fiat,
        repartition.crypto,
        repartition.coffre,
        repartition.cagnotte,
        repartition.earning,
    ];

    const reparition_amount_default = [100, 200, 300, 400, 500];

    const getRepartition = async () => {
        try {
            const response = await PnlService.getRepartitionGlobal();
            setRepartition({ ...response });
        } catch (err: any) {
            alert_message.error(err);
        }
    };

    const bg_color: string[] = [
        "#0672ff",
        "#039908",
        "#f5b221",
        "#ef004c",
        "#3b28d5",
    ];

    const total =
        repartition.fiat +
        repartition.coffre +
        repartition.cagnotte +
        repartition.crypto +
        repartition.earning;

    const DoughnutData = useMemo<any>(() => {
        const priceArray = [
            repartition.fiat,
            repartition.crypto,
            repartition.coffre,
            repartition.cagnotte,
            repartition.earning,
        ];

        if (priceArray.reduce((sum, num) => sum + num, 0) > 0) {
            setDoughnutProcessing(true);
        } else {
            setDoughnutProcessing(false);
        }

        return {
            labels: labels,
            datasets: [
                {
                    label: " ",
                    data: priceArray,
                    backgroundColor: bg_color,
                    cutout: "80%",
                    borderWidth: 4,
                    borderColor: isDark ? "#ffffff" : "#0F0F0F",
                },
            ],
        };
    }, [repartition]);

    const defautlData = {
        labels: labels,
        datasets: [
            {
                label: " ",
                data: [30, 20, 50, 100, 200],
                backgroundColor: bg_color,
                cutout: "80%",
                borderWidth: 4,
            },
        ],
    };

    useEffect(() => {
        getRepartition();
    }, []);
    return (
        <div className="col-span-12 mt-8 sm:col-span-12 lg:col-span-6">
            <div className="intro-y flex h-10 items-center">
                <h2 className="mr-5 truncate text-lg font-medium">
                    {translate("Dashboard", "Repartition_Title")}
                </h2>
            </div>
            {doughnutProcessing && doughnutProcessing === true ? (
                <div className="intro-y box mt-5 flex justify-center items-center h-[450px] dark:!border-2 dark:!border-slate-700 dark:!rounded-md">
                    <div className="w-1/2">
                        <div className="">
                            <div className="h-[125px] md:!h-[170px] flex justify-center items-center">
                                <Doughnut
                                    options={doughnutOptions}
                                    data={DoughnutData}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-1/2">
                        <div className="">
                            {labels.map((elt: any, i: number) => {
                                const class_1 =
                                    i === 0
                                        ? "flex items-center"
                                        : "mt-3 flex items-center";
                                return (
                                    <div
                                        className={`${class_1} max-w-[140px] md:!max-w-[180px] max-h-[10px] md:!max-h-[15px] text-xs md:!text-sm`}
                                        key={i}
                                    >
                                        <div
                                            className="mr-3 h-2 w-2 rounded-full"
                                            style={{
                                                background: bg_color[i],
                                            }}
                                        ></div>
                                        <span className="truncate">{elt}</span>
                                        <span className="ml-auto font-medium">
                                            {(
                                                ((reparition_amount[i] || 0) /
                                                    total) *
                                                100
                                            ).toFixed(2) + "%"}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            ) : doughnutProcessing === false ? (
                <>
                    <div className="intro-y box mt-5 p-5 flex justify-center items-center h-[392px] blur">
                        <div className="w-1/2">
                            <div className="">
                                <div className="h-[125px] md:!h-[170px] flex justify-center items-center">
                                    <Doughnut
                                        options={doughnutOptions}
                                        data={defautlData}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-1/2">
                            <div className="">
                                {labels.map((elt: any, i: number) => {
                                    const class_1 =
                                        i === 0
                                            ? "flex items-center"
                                            : "mt-3 flex items-center";
                                    return (
                                        <div
                                            className={`${class_1} max-w-[140px] md:!max-w-[180px] max-h-[10px] md:!max-h-[15px] text-xs md:!text-sm`}
                                            key={i}
                                        >
                                            <div
                                                className="mr-3 h-2 w-2 rounded-full"
                                                style={{
                                                    background: bg_color[i],
                                                }}
                                            ></div>
                                            <span className="truncate">
                                                {elt}
                                            </span>
                                            <span className="ml-auto font-medium">
                                                {(
                                                    ((reparition_amount_default[
                                                        i
                                                    ] || 0) /
                                                        1500) *
                                                    100
                                                ).toFixed(2) + "%"}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <span className="absolute z-1 top-[50%] w-full flex justify-center text-center pr-[25px] pl-[25px]">
                        {translate("Dashboard", "No_transaction_found")}
                    </span>
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

export default StackingChartGlobal;
