import React from "react";
import "animate.css";
import { ModalProvider } from "../../../../Extra/Modal/ModalContext";
import BtnStopSubsModal from "./btnStopSubsModal";

const StopSubsModal = ({
    id,
    reload,
    setReload,
}: {
    id: string;
    reload?: boolean;
    setReload?: (value: boolean) => void;
}) => {
    return (
        <>
            <ModalProvider>
                <BtnStopSubsModal
                    id={id}
                    reload={reload}
                    setReload={setReload}
                />
            </ModalProvider>
        </>
    );
};

export default StopSubsModal;
