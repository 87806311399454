import React from "react";
import Select from "react-select";
import { AppMode } from "../../../globalState/reducers/Theme.reducer";
import useTheme from "../../../Services/Hooks/useTheme";

interface SelectInputProps {
    id?: string;
    options: any;
    components?: any;
    value?: any;
    onChange?: (value: any) => void;
    required?: boolean;
    defaultValue?: any;
    isMulti?: boolean;
    minHeight?: number;
    isSearchable?: boolean;
}

const SelectInput = ({
    id,
    options,
    components,
    value,
    onChange,
    required,
    defaultValue,
    isMulti,
    minHeight,
    isSearchable,
}: SelectInputProps) => {
    const { mode } = useTheme();
    const menuPortalTarget =
        typeof document !== "undefined" ? document.body : null;
    return (
        <Select
            id={id}
            options={options}
            className="bg-select-view-other w-full"
            components={components}
            value={value}
            onChange={onChange}
            menuPortalTarget={menuPortalTarget}
            styles={{
                // Styles pour le contrôle du select (champ visible)
                control: (base) =>
                    mode === AppMode.LIGHT // Vérifie si le mode est clair
                        ? {
                              ...base,
                              minHeight: minHeight ? minHeight : 46,
                              borderRadius: "0.5rem",
                              borderColor: "#E2E8F0", // Couleur de bordure en mode clair
                              background: "transparent", // Fond transparent en mode clair
                              color: "rgb(30 41 59)", // Couleur du texte en mode clair
                          }
                        : {
                              // Sinon, pour le mode sombre
                              ...base,
                              minHeight: minHeight ? minHeight : 46,
                              borderRadius: "0.5rem",
                              borderColor: "#334155 !important", // Couleur de bordure en mode sombre
                              background: "transparent", // Fond transparent en mode sombre
                              color: "#FFF", // Couleur du texte en mode sombre
                          },
                // Styles pour les options du select
                option: (base) =>
                    mode === AppMode.LIGHT // Vérifie si le mode est clair
                        ? {
                              ...base,
                              minHeight: minHeight ? minHeight : 46,
                              borderRadius: "0.5rem",
                              borderColor: "#334155 !important", // Bordure des options en mode clair
                              background: "#fff", // Fond blanc pour les options en mode clair
                              color: "#000", // Couleur du texte en mode clair
                              "&:hover": {
                                  // Effet au survol
                                  color: "#fff",
                                  background:
                                      "radial-gradient(circle at center,#3695D2 0%,#303B8E 100%)",
                              },
                          }
                        : {
                              // Sinon, pour le mode sombre
                              ...base,
                              minHeight: minHeight ? minHeight : 46,
                              borderRadius: "0.5rem",
                              borderColor: "#334155 !important", // Bordure des options en mode sombre
                              background: "#0f0f0f", // Fond sombre pour les options
                              color: "#FFF", // Couleur du texte en mode sombre
                              "&:hover": {
                                  // Effet au survol
                                  color: "#fff",
                                  background:
                                      "radial-gradient(circle at center,#3695D2 0%,#303B8E 100%)",
                              },
                          },
                // Styles pour le menu déroulant
                menu: (base) =>
                    mode === AppMode.LIGHT // Vérifie si le mode est clair
                        ? {
                              ...base,
                              borderRadius: "0.5rem",
                              padding: "5px",
                              borderColor: "#E2E8F0", // Bordure en mode clair
                              background: "#fff", // Fond blanc en mode clair
                          }
                        : {
                              // Sinon, pour le mode sombre
                              ...base,
                              borderRadius: "0.5rem",
                              padding: "5px",
                              borderColor: "#334155 !important",
                              borderWidth: "1px", // Bordure en mode sombre
                              background: "#1a1a1a", // Fond sombre en mode sombre
                          },
                menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999, // Applique un z-index élevé pour le menu.
                }),
            }}
            required={!!required} // Indique si le champ est requis
            defaultValue={defaultValue} // Valeur par défaut
            isMulti={!!isMulti} // Si plusieurs sélections sont autorisées
            isSearchable={isSearchable === undefined ? true : !!isSearchable} // Si la recherche est activée
        />
    );
};

export default SelectInput;
