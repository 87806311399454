import React, { useState } from "react";
import "react-phone-input-2/lib/bootstrap.css";
import translator from "../../../../Extra/Translation/Translate";
import Swal from "sweetalert2";
import alert_message from "../../../../../Services/Alert/AlertMessage";
import { renderToStaticMarkup } from "react-dom/server";
import { useNavigate } from "react-router-dom";
import FidelityService from "../../../../../Services/FidelityService/FidelityService";
import { useFlyoutContext } from "../../../../../App/Provider/FlyOutProvider";
export const BtnStopSubsModal = ({
    id,
    reload,
    setReload,
}: {
    id: string;
    reload?: boolean;
    setReload?: (value: boolean) => void;
}) => {
    const { translate } = translator();
    const [processing, setProcessing] = useState<boolean>(false);
    const { setFlyoutOpen, setActiveComponent } = useFlyoutContext();
    const navigate = useNavigate();

    const stopSubsById = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        event.preventDefault();
        setProcessing(true);

        const text = renderToStaticMarkup(
            <>
                <p>{translate("SUBSCRIPTION2", "Description_1")}</p>
                <br />
                <p>{translate("SUBSCRIPTION2", "Description_2")}</p>
            </>,
        );

        Swal.fire({
            title: translate("SUBSCRIPTION2", "Stop_My_Subscription"),
            html: text,
            showCloseButton: true,
            // showCancelButton: true,
            confirmButtonColor:
                "radial-gradient(circle at center,#3695D2 0%,#303B8E 100%)",
            confirmButtonText: translate("SUBSCRIPTION2", "Confirm"),
            // cancelButtonText: translate("Admin_Assets", "Changer d’abonnement"),
            showDenyButton: true,
            denyButtonText: translate("SUBSCRIPTION2", "Change_Subscription"), // Replace with your actual translation key
        }).then((res) => {
            if (res.isConfirmed) {
                try {
                    const promise = FidelityService.stopSubsById(
                        Number(id),
                        translate,
                    );

                    alert_message
                        .promise(
                            translate("BACKEND_RESPONSE", "Update_In_Progress"),
                            promise,
                        )
                        .then(() => {
                            if (setReload) setReload(!reload);
                        })
                        .finally(() => setProcessing(false));
                    setFlyoutOpen(false);
                    setActiveComponent(undefined);
                } catch (error) {}
            } else if (res.isDenied) {
                navigate("/subscription");
            }
        });
    };

    return (
        <button
            type="button"
            className="btn !bg-red-600 text-white w-56 hidden md:block"
            onClick={(event) => stopSubsById(event)}
        >
            {translate("SUBSCRIPTION", "Stop_subs")}
        </button>
    );
};

export default BtnStopSubsModal;
